















import { Component, Mixins } from 'vue-property-decorator'
import { MetaInfo } from 'vue-meta'

// components
import NewsForm from '@/components/forms/news/NewsForm.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import ManagerNewsModule from '@/store/modules/manager/news'
import { NewsStoreData } from '@/store/types'

@Component({
  components: {
    NewsForm,
  },
})
export default class NewsNew extends Mixins(NotifyMixin) {

  private form: NewsStoreData = {
    courseIds: [],
    desktopMediaId: undefined as unknown as number,
    mobileMediaId: undefined as unknown as number,
    name: '',
    text: '',
    url: '',
    viewEndAt: '',
    viewLimit: '' as unknown as number,
    viewStartAt: '',
  }

  private createNews(form: NewsStoreData) {
    const requestForm = {
      ...form,
      desktopMediaId: form.desktopMediaId && (form.desktopMediaId as unknown as number[])[0] || undefined as unknown as number,
      courseIds: form.courseIds.length === 1 && !form.courseIds[0] ? [] : form.courseIds,
      mobileMediaId: form.mobileMediaId && (form.mobileMediaId as unknown as number[])[0] || undefined as unknown as number,
    }
    ManagerNewsModule.saveNews(requestForm)
      .then(() => {
        this.notifySuccess('Новость успешно создана.')
        this.$router.push({ name: 'manager.control.news' })
      })
      .catch(this.notifyError)
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Создание новости',
    }
  }
}
